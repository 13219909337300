import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { NotificationManager } from "react-notifications";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import _ from "lodash";
import BarcodePo from ".././BarcodePo";
import Iframe from "react-iframe";



export default class ReturnOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
     returnOrderState:[]
    };
  }

componentDidMount() {
 this.returnOrders()
  }

 

returnOrders=()=>{
axios.get(`/po/getReturnOrderForSupplier?supplierUserId=${jwt_decode(localStorage.getItem("token")).id
}`).then(result=>{
  console.log(result.data);
  this.setState({returnOrderState:result.data})
})
}

  //csv file download button



  render() {
    return (
     <div>
         <table className="table table-hover">
                  <thead>
                    <tr>
                     
                      <th scope="col">Chanel ID</th>
                      <th scope="col">Return Date</th> 
                      <th scope="col">TITLE</th>
                      <th scope="col">SKU</th>
                      <th scope="col">QTY</th>
                      <th scope="col">Price</th>
                      <th scope="col">Marketplace</th>
                    </tr>
                  </thead>
                  <tbody>
                
								{
								   !_.isEmpty(this.state.returnOrderState)
									?  
                  this.state.returnOrderState.map((item, index) => {
											
											return(
												<tr key={index} >
													<td >{item.orderNumber}</td>
                          <td >{item.updatedAt.split('T')[0]}</td>
                          <td >{item.productTitle}</td>
                          <td >{item.sku}</td>
                          <td >{item.productInfo.quantity}</td>
                          <td >&#8360; {item.orderAmt.totalAmount}</td>
                          <td >&#8360; {item.marketPlace}</td>

												</tr>
											)
										})
									:
										<tr>
											<td colSpan="5" className="text-center" style={{fontSize: "20px"}}>No Data Found</td>
										</tr>
								}
                
                        
                
                    
                
                  </tbody>
                </table>
     </div>
    );
  }
}
