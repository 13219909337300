import React, { Component } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { NotificationManager } from "react-notifications";
import "../../assets/css/productList.css";
import { Link } from "react-router-dom";
import _ from "lodash";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Modal, Button } from "react-bootstrap";
import { getPriceFormatter } from "../../helpers/index.js";
import { log } from "util";
import DataTable from 'react-data-table-component';

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`;

export default class MerchantCustomerList extends Component {
  constructor() {
    super();
    this.state = {
      productTotalCount: 0,
      productsData: [],
      isLoaded: false,
      productImageUrl: "",
      currentPage: parseInt(localStorage.getItem("p.currentpagea")) || 1,
      showPerPage: 50,
      pageCount: 0,
      searchName: "",
      searchSku: "",
      errors: {},
      loading: true,
      modalShow: false,
      deleteProductId: "",
      deleteProductName: "",
      data:[]
    };
  }

  componentDidMount() {
    this.getCustomerid();
  }


// arr.map((key, val) => ({ acc: val }) )

// data=this.state.productsData.map((key,item)=>({
//   SerialNo: 1,
//   StoreDomain: "Beetlejuice",
//   FirstName: "1988",
//   LastName: "92",
//   Email: "Tim Burton",

// }))

// data = [
//   {
//     SerialNo: 1,
//     StoreDomain: "Beetlejuice",
//     FirstName: "1988",
//     LastName: "92",
//     year: "Tim Burton",
//   },

// ]

// data = [
//   {
//      id:2,
//     title:"quick",
//     FirstName:"ygi",
//     LastName:"kumar",
//     Email:"Email"
//   },
//   // {
//   //   id:2,
//   //   StoreDomain:"quick",
//   //   FirstName:"ygi",
//   //   LastName:"kumar",
//   //   Email:"Email"
//   // }
//  ]

  getCustomerid() {
    this.setState({ loading: true });
    //console.log("iddd iss-->", jwt_decode(localStorage.getItem("token")).id);
    axios
      .get(
        `/po/getCustomerid?id=${jwt_decode(localStorage.getItem("token")).id}`
      )
      .then((result) => {
        console.log('getCustomerid',result.data)
        this.setState({ loading: false });
        this.setState({ productsData: result.data });
   
      });
  }
  
  storeUniqueId = (item) => {
    localStorage.setItem("code", item);
    localStorage.setItem("from", "supplier");
  };

  handleDeleteProduct = (item) => {
    this.setState({
      deleteProductId: item._id,
      modalShow: true,
      deleteProductName: item.name,
    });
  };

  hardDeleteProduct() {
    this.setState({ loading: true, modalShow: false });
    axios
      .get(`/add/deleteProduct/${this.state.deleteProductId}`)
      .then((result) => {
        if (result.data === 1) {
          NotificationManager.success("Product deleted successfully");
          this.getProductsData(this.state.currentPage, this.state.showPerPage);
        } else NotificationManager.error("Falied to delete product, Try again");
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        NotificationManager.error("Falied to delete product, Try again");
      });
  }

  softDeleteProduct() {
    this.setState({ loading: true, modalShow: false });
    axios
      .get(`/add/softDeleteProduct/${this.state.deleteProductId}`)
      .then((result) => {
        if (result.data === 1) {
          NotificationManager.success("Product deleted successfully");
          this.getProductsData(this.state.currentPage, this.state.showPerPage);
        } else NotificationManager.error("Falied to delete product, Try again");
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        NotificationManager.error("Falied to delete product, Try again");
      });
  }
	
	
	
	
	downloadCustomer() {
    let errors = {};
    let fromDate = this.state.fromDate;
    let toDate = this.state.toDate;
    let name = document.getElementById("searchCustomer").value;
    if (!isEmpty(fromDate) && !isEmpty(toDate)) {
      let d = new Date(fromDate);
      if (d.getFullYear() > 9999) {
        errors.fromDate = "From Date must be less than 9999";
      } else if (d.getFullYear() < 1950) {
        errors.fromDate = "From Date must be greater than 1950";
      }
      let d1 = new Date(toDate);
      if (d1.getFullYear() > 9999) {
        errors.toDate = "To Date must be less than 9999";
      } else if (d1.getFullYear() < 1950) {
        errors.toDate = "To Date must be greater than 1950";
      }
      if (isEmpty(errors)) {
        const date1 = new Date(fromDate);
        const date2 = new Date(toDate);
        const diffTime = date2 - date1;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        if (diffDays < 0)
          errors.toDate = "End date needs to be after From date";
        else {
          let obj = {
            from: fromDate,
            to: toDate,
            status: document.getElementById("selectStatus").value,
            supplierId: jwt_decode(localStorage.getItem("token")).id,
            page: this.state.currentPage,
            limit: this.state.showPerPage,
          };
          this.setState({ loading: true });
          axios.post(`/po/downloadCustomerCsv`, obj).then((result) => {
            this.setState({ loading: false });
            if (result.data.error == 0) {
              window.open(result.data.response);
            } else if (result.error == 1) {
              alert(result.response);
            }
          });
        }
      }
      this.setState({ errors });
    } else if (!isEmpty(name)) {
      let obj = {
        name: name,
        supplierId: jwt_decode(localStorage.getItem("token")).id,
      };
      this.setState({ loading: true });
      axios.post(`/po/downloadCustomerCsv`, obj).then((data) => {
        let result = data.data;
        this.setState({ loading: false });
        if (result.error == 0) {
          window.open(result.response);
        } else if (result.error == 1) {
          alert(result.response);
        }
      });
    } else {
      let obj = {
        from: fromDate,
        to: toDate,
        status: document.getElementById("selectStatus").value,
        supplierId: jwt_decode(localStorage.getItem("token")).id,
        page: this.state.currentPage,
        limit: this.state.showPerPage,
      };
      this.setState({ loading: true });
      axios.post(`/po/downloadCustomerCsv`, obj).then((data) => {
        let result = data.data;
        this.setState({ loading: false });
        if (result.error == 0) {
          window.open(result.response);
        } else if (result.error == 1) {
          alert(result.response);
        }
      });
    }
  }
  
  
  
  
  handlePageClick = (e) => {
    if (_.isEmpty(this.state.searchName))
      this.getProductsData(e.selected + 1, this.state.showPerPage);
    else this.findByName(e.selected + 1, this.state.showPerPage);
  };

  findByName(page, perPage) {
    this.setState({ loading: true });
    axios
      .get(
        `/add/findCustomerByName?name=${
          this.state.searchName
        }&page=${page}&limit=${perPage}&id=${
          jwt_decode(localStorage.getItem("token")).id
        }`
      )
      .then((result) => {
        console.log(result);
        if (result.data === 1) {
          NotificationManager.success("No Product Found on this Name");
          this.setState({ loading: false });
        } else {
          this.setState({
            isLoaded: true,
            productsData: result.data.items,
            currentPage: page,
            pageCount: Math.ceil(
              result.data.totalCount / this.state.showPerPage
            ),
            loading: false,
            productTotalCount: result.data.totalCount,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        NotificationManager.error("Failed to get product data");
      });
  }

  triggerHanleSearch = (e) => {
    e.persist();
    if (e.key === "Enter") {
      this.searchFunc();
    }
  };

  searchFunc() {
    let errors = {};
    if (!_.isEmpty(this.state.searchName)) {
      this.findByName(1, this.state.showPerPage);
    } else if (!_.isEmpty(this.state.searchSku)) {
      this.setState({ loading: true });
      axios
        .get(
          `/add/findBySku?sku=${this.state.searchSku}&id=${
            jwt_decode(localStorage.getItem("token")).id
          }`
        )
        .then((result) => {
          console.log(result.data.length, result.data);
          if (result.data === 1) {
            NotificationManager.success("No Product Found on this SKU");
            this.setState({ loading: false });
          } else {
            let temp = [];
            temp.push(result.data.obj);
            this.setState({
              isLoaded: true,
              productsData: temp,
              pageCount: Math.ceil(temp.length / this.state.showPerPage),
              currentPage: 1,
              loading: false,
              productTotalCount: temp.length,
            });
            localStorage.setItem("p.currentpagea", 1);
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
          NotificationManager.error("Failed to get product data");
        });
    } else {
      // errors.err = "Please provide us any value to search"
      // this.setState({errors})
      this.getProductsData(1, this.state.showPerPage);
    }
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.searchFunc();
  };

  handleShowPerPage = (e) => {
    e.preventDefault();
    this.setState({ showPerPage: e.target.value });
    this.getProductsData(1, e.target.value);
  };

  columns = [
    {
        name: 'Serial No.',
        selector: row => row.SerialNo,
        sortable: true,
    },
    {
        name: 'StoreDomain',
        selector: row => row.StoreDomain,
        sortable: true,
    },
    {
        name: 'FirstName',
        selector: row => row.FirstName,
        sortable: true,
    },
    {
      name: 'LastName',
      selector: row => row.LastName,
      sortable: true,
  },
  {
      name: 'Email',
      selector: row => row.Email,
      sortable: true,
  },
];

  render() {
    if (_.isEmpty(this.state.productsData) && this.state.isLoaded) {
      return (
        <div>
          <br></br>
          <h4 className="text-center my-5">No Products Found</h4>
        </div>
      );
    } else {
      return (
        <div>
          <SyncLoader
            css={override}
            size={15}
            margin={6}
            color={"#3E3E3E"}
            loading={this.state.loading}
          />
          <br></br>
          <div className={this.state.loading ? "parentDisable" : ""}>
            <div className="container-fluid">
          {/* <input type="date"></input>
          <input type="date"></input>
          <Button type="submit">Find</Button> */}
            </div>
            <div className="container-fluid  ml-2" >
            <div className="col-sm-12 col-md-12 col-lg-2 col-xl-4" >
              <div className="form-group text-center">
                <input type="text"
                  style={{marginTop:"28px"}}
                  placeholder="Search..."
                  className="form-control"
                  value={this.state.searchName}
                  onKeyPress={this.triggerHanleSearch}
                  onChange={(e) => this.setState({searchName: e.target.value, searchSku: "", errors: {}})}
                  title="Hit Enter for the result"></input>
              </div>
            </div>
    <div className="col-sm-12 col-md-4 col-lg-2 col-xl-2" >
                    <div className="form-group">
                      <h6>
                        From Date &nbsp;
                        <span
                          onClick={() =>
                            (document.getElementById("fromDate").value = "")
                          }
                          style={{ cursor: "pointer" }}
                        >
                          (Clear)
                        </span>
                      </h6>
                      <input
                        type="date"
                        className="form-control"
                        id="fromDate"
                        max="9999-12-01"
                        min="1950-12-31"
                        placeholder="From Date"
                        value={this.state.fromDate}
                        onChange={(e) =>
                          this.setState({
                            fromDate: e.target.value,
                            searchSku: "",
                          })
                        }
                      />
                      {!isEmpty(this.state.errors.fromDate) && (
                        <span className="text-danger">
                          {this.state.error.fromDate}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-2 col-xl-2" >
                    <div className="form-group">
                      <h6>
                        To Date &nbsp;
                        <span
                          onClick={() =>
                            (document.getElementById("toDate").value = "")
                          }
                          style={{ cursor: "pointer" }}
                        >
                          (Clear)
                        </span>
                      </h6>
                      <input
                        type="date"
                        className="form-control"
                        id="toDate"
                        max="9999-12-01"
                        min="1950-12-31"
                        placeholder="To Date"
                        value={this.state.toDate}
                        onChange={(e) =>
                          this.setState({
                            toDate: e.target.value,
                            searchSku: "",
                          })
                        }
                      />
                      {!isEmpty(this.state.errors.toDate) && (
                        <span className="text-danger">
                          {this.state.errors.toDate}
                        </span>
                      )}
                    </div>
                  
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-2 col-xl-2" style={{marginTop:"26px"}}>
                    <button
                      className="btn-fill btn btn_new btn-default"
                      style={{ margin: "0px", width: "100%" }}
                      onClick={() => this.downloadCustomer()}
                    >
                      Download
                    </button>
                  </div>
          </div>
            <div className="container-fluid">
              <div className="card" style={{ padding: "1em" }}>
            
                <div className="table-responsive">
                <DataTable
                columns={this.columns}
                data={this.state.productsData.map((item,index)=>({
                  SerialNo: index+1,
                  StoreDomain: item.storeDomain,
                  FirstName:item.firstName?item.firstName : item.customer.firstName?item.customer.firstName:"NA" ,
                  LastName: item.lastName ?item.lastName: item.customer.lastName ? item.customer.lastName:"NA",
                  Email: item.email ? item.email: item.customer.email ? item.customer.email:"NA",
                }))}
                pagination  
                responsive
                striped
                highlightOnHover
                selectableRows          
        />
                  {/* <table className="table table-hover ProductListTable">
                    <thead>
                      <tr className="d-flex">
                        <th scope="col">Serial No.</th>
                        <th scope="col">Store Domain</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!_.isEmpty(this.state.productsData) ? (
                        this.state.productsData.map((item, index) => {
                          const {
                            _id,
                            image,
                            name,
                            invoiceDesc,
                            code,
                            category,
                            price,
                            productUrl,
                          } = item;
                          return (
                            <tr key={index} className="d-flex">
                              <td>
                                {(this.state.currentPage - 1) *
                                  this.state.showPerPage +
                                  (index + 1)}
                              </td>

                              <td>{item.storeDomain}</td>

                              <td>{item.firstName}</td>
                              <td>{item.lastName}</td>
                              <td>{item.email}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan="7"
                            className="text-center"
                            style={{ fontSize: "20px" }}
                          >
                            No Customer Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table> */}
                </div>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={10}
                  onPageChange={this.handlePageClick.bind(this)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
