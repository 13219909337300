import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

export class StatsCard extends Component {
  render() {
    return (
      <div  className="card card-stats">

        <div className={
         (this.props.statsText==='Today Sales') ? "content red": (this.props.statsValue==='TOTAL PRODUCTS') ? "content ttlproduct" : (this.props.statsValue==='NEW SALES') ? "content newsales":(this.props.statsText==='Orders Fulfill Left') ? "content green":(this.props.statsText==='Merchants') ? "content green":(this.props.statsValue==='TOTAL CANCELLED AMOUNT') ? "content yellow":(this.props.statsValue==='TOTAL CANCELLED AMOUNT') ? "content yellow":(this.props.statsValue==='NET SALES') ? "content greenlight":(this.props.statsValue==='RTO INVOICE AMOUNT') ? "content bluesky":(this.props.statsValue==='TOTAL SALES') ? "content purple":(this.props.statsText==='Total Orders') ? "content lightyellow":(this.props.statsValue==='TOTAL CUSTOMERS') ? "content cyanttl":"content"
        } 
        style={{borderRadius:"10px"}}
       >
          <Row>
            <Col xs={3}>
              <div className="icon-big icon-warning">
                {this.props.bigIcon}
              </div>
            </Col>
            <Col xs={9} style={{marginTop:"-15px"}}>
              <div className="numbers">
                <span style={{color:"#fff",fontSize:"20px",marginTop:"-7px"}}>{this.props.statsText}</span>
                <p style={{color:"#fff" ,fontSize:"10px",marginTop:"-7px"}}>{this.props.statsValue}
                <p class={this.props.statsHintIcon} title={this.props.statsHint} ></p></p> 
              </div>
            </Col>
            <Col xs={9} >
              <div className="numbers">
                <span style={{color:"#fff",fontSize:"20px",marginTop:"-7px"}}>{this.props.statsTextUnit}</span>
                <p style={{color:"#fff" ,fontSize:"10px",marginTop:"-7px"}}>{this.props.statsValueUnit}
                <p class={this.props.statsHintIcon1} title={this.props.statsHint1} ></p></p> 
              </div>
            </Col>
          </Row>
          {/* <div className="footer">
            
            <div className="stats" style={{ cursor: "pointer" }} onClick={(e) => this.props.func()}>
              {this.props.statsIcon} <span style={{color:"#fff"}}>{this.props.statsIconText}</span>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default StatsCard;
