

      import React, { Component } from "react";
      import { NavLink } from "react-router-dom";
      import '../../assets/css/pe-icon-7-stroke.css';
      
      import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
      
      class Sidebar extends Component {
        constructor(props) {
          super(props);
          this.state = {
            width: window.innerWidth
          };
        }
        activeRoute(routeName) {
          return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
        }
        updateDimensions() {
          this.setState({ width: window.innerWidth });
        }
        componentDidMount() {
          this.updateDimensions();
          window.addEventListener("resize", this.updateDimensions.bind(this));
        }
        menuToggle(e){
          let ele = e.target.parentElement.parentElement.querySelectorAll(".collapse");
          if(!ele.length)
            return false;
          e.preventDefault();
          if(ele[0].style.display == "none"){
            ele[0].style.display = "block";
          } else {
            ele[0].style.display = "none";
          }
        }
        
        render() {
          const sidebarBackground = {
            backgroundImage: "url(" + this.props.image + ")"
          };
          
          
         
         
       
      // console.log( supplierStatus);
          return (
            <div
              id="sidebar"
              className="sidebar"
              data-color={this.props.color}
              data-image={this.props.image}
            >
             
              <div className="logo" >
                <a
                  href=""
                  className="simple-text logo-mini"
                >
                  <div className="logo-img">
                    <img src={process.env.REACT_APP_S3_URL.trim()+'/img/brandmark-design-15.png'} alt="logo_image" />
                  </div>
                </a>
               
      
       <a
        href=""
        className="simple-text logo-normal"
      >
        GLOBALI
      </a>
     
      
      
   
       
             
              </div>
              <div className="sidebar-wrapper">
                <ul className="nav" style = {{ marginTop: '0px' }}>
                  {this.state.width <= 991 ? <AdminNavbarLinks /> : null}
                  {this.props.routes.map((prop, key) => {
                    if (!prop.redirect)
                      return (
                        <li
                          className={
                            prop.upgrade
                              ? "active active-pro"
                              : this.activeRoute(prop.layout + prop.path)
                          }
                          key={key}
                        >
                          {prop.layout == 'outerUrl' ?  <a className="nav-link" href= { prop.path } target='_blank'><i className={prop.icon} /><p>{ prop.name }</p></a> :
                              <NavLink
                                to={prop.layout + prop.path}
                                className="nav-link"
                                activeClassName="active"
      
                                onClick={
                                  prop.subRoutes && prop.subRoutes.length ? this.menuToggle : ()=>{}
                                }
                              >
                                <i className={prop.icon} />
                                <p id="propnameStyle">{prop.name}</p>
                              </NavLink>
                          }
                          { prop.subRoutes && prop.subRoutes.length ? <b className="caret mt-1"></b> : "" }
                          { prop.subRoutes && prop.subRoutes.length ?
                            <div className="collapse" id={prop.name} aria-expanded="true" style={{display:"none"}}>
                                <ul>
                                   { prop.subRoutes.map((prop, key) => { 
                                     if (!prop.redirect)
                                      return (
                                        <li  className={
                                          prop.upgrade
                                            ? "active active-pro"
                                            : this.activeRoute(prop.layout + prop.path)
                                        }
                                        key={key}>
                                          {prop.layout == 'outerUrl' ?  <a className="nav-link" href= { prop.path } target='_blank'><p>{ prop.name }</p></a> : 
                                              <NavLink to={prop.layout + prop.path}
                                                    className="nav-link"
                                                    activeClassName="active">
                                                <p>{prop.name}</p>
                                            </NavLink>
                                          } 
                                            
                                        </li>
                                        );
                                      })
                                    }
                                </ul>
                            </div>
                            : ""   
                          }
                        </li>
                      );
                    return null;
                  })}
                </ul>
              </div>
            </div>
          );
        }
      }
      
      export default Sidebar;
      