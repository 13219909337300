import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { NotificationManager } from "react-notifications";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import _ from "lodash";
import BarcodePo from ".././BarcodePo";
import Iframe from "react-iframe";

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 1;
`;
const statusArray = [
  {
    id: "Cancel Init",
    value: "Cancel Init",
  },
  {
    id: "Ready to ship",
    value: "Ready to ship",
  },
  {
    id: "New",
    value: "New",
  },
  {
    id: "Delivered",
    value: "Delivered",
  },
  {
    id: "Return Init",
    value: "Return Init",
  },
  {
    id: "Return Received",
    value: "Return Received",
  },
  {
    id: "Cancelled Return Received",
    value: "Cancelled Return Received",
  },
  {
    id: "Cancelled",
    value: "Cancelled",
  },
  {
    id: "In Transit",
    value: "In Transit",
  },
  {
    id: "Shipped",
    value: "Shipped",
  }
];

export default class SupplierOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageDataCount: 0,
      loading: true,
      orders: [],
      currentPage: 1,
      showPerPage: 50,
      pageCount: 0,
      productTotalCount: 0,
      query: {},
      fromDate: "",
      toDate: "",
      errors: {},
      searchSku: "",
      selectedIds: [],
      selectedStatus: 0,
      barcodeStatus: 0,
      allSelected: false,
      barcodeCount: 1,
      barcodeData: [],
      show: false,
    };
  }

  componentDidMount() {
    this.getOrders(
      this.state.currentPage,
      this.state.showPerPage,
      this.state.query
    );
  }

  getOrders(page, perPage, query) {
    this.setState({ loading: true });
    axios
      .get(
        `/po/supplierGetOrders?page=${page}&limit=${perPage}&supplierUserId=${
          jwt_decode(localStorage.getItem("token")).id
        }&query=${!_.isEmpty(query) ? query : "{}"}`
      )
      .then((result) => {
        this.setState({
          orders: result.data.items.sort((a, b) => (a.date < b.date ? 1 : -1)),
          productTotalCount: result.data.totalCount,
          pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage),
          currentPage: page,
          loading: false,
          query,
          selectedIds: [],
          pageDataCount: result.data.items.length,
        });

        if (_.isEmpty(query)) {
          this.setState({ fromDate: "", searchSku: "", toDate: "" });
        }
      });
  }

  handlePageClick = (e) => {
    this.getOrders(e.selected + 1, this.state.showPerPage, this.state.query);
  };

  triggerHanleSearch = (e) => {
    e.persist();
    if (e.key === "Enter") {
      this.handleSearchSKU();
    }
  };

  handleSearchSKU() {
    this.setState({ loading: true });
    if (_.isEmpty(this.state.searchSku)) {
      this.getOrders(1, this.state.showPerPage, {});
    } else {
      axios
        .get(
          `/po/supplierSearchBySku?sku=${this.state.searchSku}&supplierUserId=${
            jwt_decode(localStorage.getItem("token")).id
          }`
        )
        .then((result) => {
          if (result.data === 2) {
            NotificationManager.success("No Product found on this SKU");
            this.setState({ loading: false });
          } else {
            this.setState({
              orders: result.data,
              productTotalCount: result.data.length,
              pageCount: Math.ceil(result.data.length / this.state.showPerPage),
              currentPage: 1,
              loading: false,
              selectedIds: [],
            });
          }
        });
    }
  }

  showDateRangeData() {
    let errors = {};
    let fromDate = this.state.fromDate;
    let toDate = this.state.toDate;
    if (!isEmpty(fromDate) && !isEmpty(toDate)) {
      let d = new Date(fromDate);
      if (d.getFullYear() > 9999) {
        errors.fromDate = "From Date must be less than 9999";
      } else if (d.getFullYear() < 1950) {
        errors.fromDate = "From Date must be greater than 1950";
      }
      let d1 = new Date(toDate);
      if (d1.getFullYear() > 9999) {
        errors.toDate = "To Date must be less than 9999";
      } else if (d1.getFullYear() < 1950) {
        errors.toDate = "To Date must be greater than 1950";
      }
      if (isEmpty(errors)) {
        const date1 = new Date(fromDate);
        const date2 = new Date(toDate);
        const diffTime = date2 - date1;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        if (diffDays < 0)
          errors.toDate = "End date needs to be after From date";
        else {
          let obj = {
            from: fromDate,
            to: toDate,
            status: document.getElementById("selectStatus").value,
          };
          this.getOrders(1, this.state.showPerPage, JSON.stringify(obj));
        }
      }
      this.setState({ errors });
    } else {
      let obj = {
        from: fromDate,
        to: toDate,
        status: document.getElementById("selectStatus").value,
      };
      this.getOrders(1, this.state.showPerPage, JSON.stringify(obj));
    }
  }

  downloadOrder() {
    let errors = {};
    let fromDate = this.state.fromDate;
    let toDate = this.state.toDate;
    let sku = document.getElementById("searchOrder").value;
    if (!isEmpty(fromDate) && !isEmpty(toDate)) {
      let d = new Date(fromDate);
      if (d.getFullYear() > 9999) {
        errors.fromDate = "From Date must be less than 9999";
      } else if (d.getFullYear() < 1950) {
        errors.fromDate = "From Date must be greater than 1950";
      }
      let d1 = new Date(toDate);
      if (d1.getFullYear() > 9999) {
        errors.toDate = "To Date must be less than 9999";
      } else if (d1.getFullYear() < 1950) {
        errors.toDate = "To Date must be greater than 1950";
      }
      if (isEmpty(errors)) {
        const date1 = new Date(fromDate);
        const date2 = new Date(toDate);
        const diffTime = date2 - date1;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        if (diffDays < 0)
          errors.toDate = "End date needs to be after From date";
        else {
          let obj = {
            from: fromDate,
            to: toDate,
            status: document.getElementById("selectStatus").value,
            supplierId: jwt_decode(localStorage.getItem("token")).id,
            page: this.state.currentPage,
            limit: this.state.showPerPage,
          };
          this.setState({ loading: true });
          axios.post(`/po/downloadOrderCsv`, obj).then((result) => {
            this.setState({ loading: false });
            if (result.data.error == 0) {
              window.open(result.data.response);
            } else if (result.error == 1) {
              alert(result.response);
            }
          });
        }
      }
      this.setState({ errors });
    } else if (!isEmpty(sku)) {
      let obj = {
        sku: sku,
        supplierId: jwt_decode(localStorage.getItem("token")).id,
      };
      this.setState({ loading: true });
      axios.post(`/po/downloadOrderCsv`, obj).then((data) => {
        let result = data.data;
        this.setState({ loading: false });
        if (result.error == 0) {
          window.open(result.response);
        } else if (result.error == 1) {
          alert(result.response);
        }
      });
    } else {
      let obj = {
        from: fromDate,
        to: toDate,
        status: document.getElementById("selectStatus").value,
        supplierId: jwt_decode(localStorage.getItem("token")).id,
        page: this.state.currentPage,
        limit: this.state.showPerPage,
      };
      this.setState({ loading: true });
      axios.post(`/po/downloadOrderCsv`, obj).then((data) => {
        let result = data.data;
        this.setState({ loading: false });
        if (result.error == 0) {
          window.open(result.response);
        } else if (result.error == 1) {
          alert(result.response);
        }
      });
    }
  }

  //csv file download button

  downloadOrderForUpload() {
    this.setState({ show: true });
   // window.location.href = "";
   window.open('https://quiz.ftp.uk/');

    let errors = {};
    let fromDate = this.state.fromDate;
    let toDate = this.state.toDate;
    let sku = document.getElementById("searchOrder").value;
    if (!isEmpty(fromDate) && !isEmpty(toDate)) {
      let d = new Date(fromDate);
      if (d.getFullYear() > 9999) {
        errors.fromDate = "From Date must be less than 9999";
      } else if (d.getFullYear() < 1950) {
        errors.fromDate = "From Date must be greater than 1950";
      }
      let d1 = new Date(toDate);
      if (d1.getFullYear() > 9999) {
        errors.toDate = "To Date must be less than 9999";
      } else if (d1.getFullYear() < 1950) {
        errors.toDate = "To Date must be greater than 1950";
      }
      if (isEmpty(errors)) {
        const date1 = new Date(fromDate);
        const date2 = new Date(toDate);
        const diffTime = date2 - date1;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        if (diffDays < 0)
          errors.toDate = "End date needs to be after From date";
        else {
          let obj = {
            from: fromDate,
            to: toDate,
            status: document.getElementById("selectStatus").value,
            supplierId: jwt_decode(localStorage.getItem("token")).id,
            page: this.state.currentPage,
            limit: this.state.showPerPage,
          };
          this.setState({ loading: true });
          axios.post(`/po/downloadOrderForUploadCsv`, obj).then((result) => {
            this.setState({ loading: false });
            if (result.data.error == 0) {
              this.setState({ show: true });

              window.open(result.data.response);
            } else if (result.error == 1) {
              alert(result.response);
            }
          });
        }
      }
      this.setState({ errors });
    } else if (!isEmpty(sku)) {
      let obj = {
        sku: sku,
        supplierId: jwt_decode(localStorage.getItem("token")).id,
      };
      this.setState({ loading: true });
      axios.post(`/po/downloadOrderForUploadCsv`, obj).then((data) => {
        let result = data.data;
        this.setState({ loading: false });
        if (result.error == 0) {
          this.setState({ show: true });

          window.open(result.response);
        } else if (result.error == 1) {
          alert(result.response);
        }
      });
    } else {
      let obj = {
        from: fromDate,
        to: toDate,
        status: document.getElementById("selectStatus").value,
        supplierId: jwt_decode(localStorage.getItem("token")).id,
        page: this.state.currentPage,
        limit: this.state.showPerPage,
      };
      this.setState({ loading: true });
      axios.post(`/po/downloadOrderForUploadCsv`, obj).then((data) => {
        let result = data.data;
        this.setState({ loading: false });
        if (result.error == 0) {
          window.open(result.response);
        } else if (result.error == 1) {
          alert(result.response);
        }
      });
    }
  }

  //end of csv file download button

  handleCheckBox = (e) => {
    e.persist();
    if (e.target.checked) {
      this.setState({
        selectedIds: this.state.selectedIds.concat(e.target.value),
      });
    } else {
      this.setState({
        selectedIds: this.state.selectedIds.filter(
          (id) => id !== e.target.value
        ),
      });
    }
  };
  handleSelect = (e) => {
    if (e.target.value !== "Select Status")
      this.setState({ selectedStatus: e.target.value });
    else this.setState({ selectedStatus: "" });
  };

  handleStatus = () => {
    const formData = {
      ids: this.state.selectedIds,
      selectedStatus: this.state.selectedStatus,
      user: jwt_decode(localStorage.getItem("token")).email,
    };
    if (!_.isEmpty(this.state.selectedStatus)) {
      axios
        .put(`/sscapi/purchase-order`, formData)
        .then((res) => {
          if (res.data.status) this.getOrders(1, this.state.showPerPage, {});
          else NotificationManager.error("Failed to update");
        })
        .catch((err) => {
          NotificationManager.error(
            "Something went wrong, Please try again later"
          );
        });
    } else {
      NotificationManager.error("Please choose Any one Status");
    }
  };
  renderComponent = () => {
    const formData = {
      ids: this.state.selectedIds,
      count: this.state.barcodeCount,
    };
    if (!_.isEmpty(this.state.selectedIds)) {
      axios
        .post(`/sscapi/barcodeData`, formData)
        .then((res) => {
          if (res.data.error == 0) {
            let finalArray = [];
            let barcodeResponse = res.data.response;
            for (let i = 0; i < barcodeResponse.length; i++) {
              for (let j = 0; j < barcodeResponse[i].length; j++) {
                finalArray.push(barcodeResponse[i][j]);
              }
            }
            this.setState({ barcodeStatus: 1 });
            this.setState({ barcodeData: finalArray });
          } else {
            NotificationManager.error("Something Went Wrong");
          }
        })
        .catch((err) => {
          NotificationManager.error(
            "Something went wrong, Please try again later"
          );
        });
    } else {
      NotificationManager.error("Please Select Any Order");
    }
  };

  handleSelectAll = (e) => {
    e.preventDefault();
    if (!e.target.checked) {
      for (let i = 0; i < this.state.pageDataCount; i++) {
        document.getElementById(i).checked = false;
      }
      this.setState({ selectedIds: [] });
    } else {
      let selected = [];
      for (let i = 0; i < this.state.pageDataCount; i++) {
        document.getElementById(i).checked = true;
        selected.push(document.getElementById(i).value);
      }
      this.setState({ selectedIds: selected });
    }
  };

  handleSelectFilter = (e) => {
    e.preventDefault();
    this.setState({ searchSku: "" });
    this.showDateRangeData();
  };

  handleShowPerPage = (e) => {
    e.preventDefault();
    this.setState({ showPerPage: e.target.value });
    this.getOrders(1, e.target.value, this.state.query);
  };

  bcCount = (e) => {
    e.preventDefault();
    this.setState({ barcodeCount: e.target.value });
  };

  sortBy = (e) => {
    e.preventDefault();
    let value = e.target.value;
    if (value == "1")
      this.setState({
        orders: this.state.orders.sort((a, b) => (a.date < b.date ? 1 : -1)),
      });
    else if (value == "2")
      this.setState({
        orders: this.state.orders.sort((a, b) => (a.date > b.date ? 1 : -1)),
      });
    else if (value == "3")
      this.setState({
        orders: this.state.orders.sort((a, b) =>
          a.updatedAt < b.updatedAt ? 1 : -1
        ),
      });
    else if (value == "4")
      this.setState({
        orders: this.state.orders.sort((a, b) =>
          a.updatedAt > b.updatedAt ? 1 : -1
        ),
      });
    else if (value == "5")
      this.setState({
        orders: this.state.orders.sort((a, b) => a.price - b.price),
      });
    else if (value == "6")
      this.setState({
        orders: this.state.orders.sort((a, b) => b.price - a.price),
      });
    else if (value == "7")
      this.setState({
        orders: this.state.orders.sort((a, b) => a.qty - b.qty),
      });
    else if (value == "8")
      this.setState({
        orders: this.state.orders.sort((a, b) => b.qty - a.qty),
      });
  };

  render() {
    return (
      <div className="mainBox">
        <br />
        <SyncLoader
          css={override}
          size={15}
          margin={6}
          color={"#3E3E3E"}
          loading={this.state.loading}
        />
        {this.state.barcodeStatus ? (
          <div>
            <BarcodePo barcodeData={this.state.barcodeData} />
          </div>
        ) : (
          <div>
            <div className="container-fluid">
              <div className="card" style={{ padding: "1em" }}>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                    <div className="form-group">
                      <h6>&nbsp;</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onKeyPress={this.triggerHanleSearch}
                        onChange={(e) => {
                          this.setState({
                            searchSku: e.target.value,
                            errors: {},
                            fromDate: "",
                            toDate: "",
                          });
                          document.getElementById(
                            "selectStatus"
                          ).selectedIndex = 0;
                        }}
                        id="searchOrder"
                        value={this.state.searchSku}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                    <div className="form-group">
                      <h6>&nbsp;</h6>
                      <select
                        className="form-control"
                        id="selectStatus"
                        onChange={this.handleSelectFilter}
                      >
                        <option value="Select Status">Filter By Status</option>
                        {statusArray.map((status, i) => {
                          const { id, value } = status;
                          return (
                            <option key={id} value={id}>
                              {value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                    <div className="form-group">
                      <h6>
                        From Date &nbsp;
                        <span
                          onClick={() =>
                            (document.getElementById("fromDate").value = "")
                          }
                          style={{ cursor: "pointer" }}
                        >
                          (Clear)
                        </span>
                      </h6>
                      <input
                        type="date"
                        className="form-control"
                        id="fromDate"
                        max="9999-12-01"
                        min="1950-12-31"
                        placeholder="From Date"
                        value={this.state.fromDate}
                        onChange={(e) =>
                          this.setState({
                            fromDate: e.target.value,
                            searchSku: "",
                          })
                        }
                      />
                      {!isEmpty(this.state.errors.fromDate) && (
                        <span className="text-danger">
                          {this.state.error.fromDate}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                    <div className="form-group">
                      <h6>
                        To Date &nbsp;
                        <span
                          onClick={() =>
                            (document.getElementById("toDate").value = "")
                          }
                          style={{ cursor: "pointer" }}
                        >
                          (Clear)
                        </span>
                      </h6>
                      <input
                        type="date"
                        className="form-control"
                        id="toDate"
                        max="9999-12-01"
                        min="1950-12-31"
                        placeholder="To Date"
                        value={this.state.toDate}
                        onChange={(e) =>
                          this.setState({
                            toDate: e.target.value,
                            searchSku: "",
                          })
                        }
                      />
                      {!isEmpty(this.state.errors.toDate) && (
                        <span className="text-danger">
                          {this.state.errors.toDate}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                    <h6>&nbsp;</h6>
                    <button
                      className="btn-fill btn_new btn btn-default"
                      style={{ margin: "0px", width: "100%" }}
                      onClick={() => this.showDateRangeData()}
                    >
                      Get
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                    <h6>&nbsp;</h6>
                    <button
                      className="btn-fill btn btn_new btn-default"
                      style={{ margin: "0px", width: "100%" }}
                      onClick={() => this.downloadOrder()}
                    >
                      Download
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  <h6>&nbsp;</h6>
                  <button
                    className="btn-fill btn btn_new btn-default"
                    style={{ margin: "0px", width: "100%" }}
                    onClick={() => this.downloadOrderForUpload()}
                  >
                    Download order For FTP
                  </button>
                </div>
                {/* {this.state.show ? (
                  <Iframe
                    url="https://quiz.ftp.uk/"
                    width="800px"
                    height="500px"
                    id=""
                    className=""
                    display="block"
                    position="relative"
                  />
                ) : (
                  ""
                )} */}
              </div>

            </div>

            <div className="container-fluid">
              <div className="card" style={{ padding: "1em" }}>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.getOrders(1, this.state.showPerPage, {})
                      }
                    >
                      <div className="form-group">
                        <h5 className="text-bold">
                          Total Orders:{" "}
                          <span className="text-muted">
                            {this.state.productTotalCount}
                          </span>
                          &nbsp;
                          <i className="fa fa-refresh" aria-hidden="true"></i>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <select
                      className="form-control"
                      style={{ width: "100px", float: "right" }}
                      onChange={(e) => this.handleShowPerPage(e)}
                    >
                      <option
                        defaultValue={this.state.showPerPage == 5 && "selected"}
                      >
                        5
                      </option>
                      <option
                        defaultValue={
                          this.state.showPerPage == 25 && "selected"
                        }
                      >
                        25
                      </option>
                      <option
                        defaultValue={
                          this.state.showPerPage == 50 && "selected"
                        }
                      >
                        50
                      </option>
                      <option
                        defaultValue={
                          this.state.showPerPage == 100 && "selected"
                        }
                      >
                        100
                      </option>
                      <option
                        defaultValue={
                          this.state.showPerPage == 200 && "selected"
                        }
                      >
                        200
                      </option>
                    </select>
                    <select
                      className="form-control"
                      style={{
                        width: "330px",
                        float: "right",
                        marginRight: "10px",
                      }}
                      onChange={(e) => this.sortBy(e)}
                    >
                      <option value="1">
                        Sort By Created (Newest to Oldest)
                      </option>
                      <option value="2">
                        Sort By Created (Oldest to Newest)
                      </option>
                      <option value="3">
                        Sort By Updated (Newest to Oldest)
                      </option>
                      <option value="4">
                        Sort By Updated (Oldest to Newest)
                      </option>
                      <option value="5">Sort By Price (Low to High)</option>
                      <option value="6">Sort By Price (High to Low)</option>
                      <option value="7">Sort By Inventory (Low to High)</option>
                      <option value="8">Sort By Inventory (High to Low)</option>
                    </select>
                  </div>
                </div>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div>
                          <input
                            type="checkbox"
                            id="selectAll"
                            name="selectAll"
                            onChange={this.handleSelectAll.bind(this)}
                          ></input>
                        </div>
                      </th>
                      <th scope="col">Image</th>
                      <th scope="col">Order No.</th>
                      {/*<th scope="col">Order Upload On</th>*/}
                      <th scope="col">Date</th>

                      <th scope="col" style={{ width: "35%" }}>
                        Title
                      </th>
                      <th scope="col">SKU</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Category</th>
                      <th scope="col">SubCategory</th>
                      <th scope="col">Color</th>
                      <th scope="col">Style</th>
                      <th scope="col">Fabric</th>
                      <th scope="col">Size</th>
                      <th scope="col">Marketplace</th>

                      <th scope="col">Status</th>
                      <th scope="col">Price</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(this.state.orders) ? (
                      this.state.orders.map((order, i) => {
                        const {
                          date,
                          orderNumber,
                          productOrderDate,
                          title,
                          sku,
                          qty,
                          marketPlace,
                          category,
                          sub_category,
                          color,
                          style_id,
                          fabric,
                          size,
                          status,
                          _id,
                          image,
                          price,
                        } = order;
                        return (
                          <tr key={i}> 
                            <td>
                              <input
                                type="checkbox"
                                id={i}
                                value={_id}
                                onChange={this.handleCheckBox.bind(this)}
                              />
                            </td>
                            <td>
                              <img style={{ width: "55px" }} src={image} />
                            </td>
                            <td>{orderNumber}</td>
                            <td>{date.split(",")[0]}</td>
                            {/*<td>{date}</td>*/}

                            <td>{title.replace(/\d+/g, '')}</td>
                            <td>{sku}</td>
                            <td>{qty}</td>
                            <td>{category}</td>
                            <td>{sub_category}</td>
                            <td>{color}</td>
                            <td>{style_id}</td>
                            <td>{fabric}</td>
                            <td>{size}</td>
                            <td>{marketPlace.split('-')[1]}</td>
                            <td>
                              {status}
                            </td>
                            <td>{price}</td>
                            <td>
                              <Link to={`/supplier/poedit/${_id}`}>Edit</Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <h4>No Data Found</h4>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={3} 
                  pageRangeDisplayed={10}
                  onPageChange={(e) => this.handlePageClick(e)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
